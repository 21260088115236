import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTeam: null,
  teams: [],
  agents: [],
  totalAgent: 0,
};
const agentSlice = createSlice({
  name: "agent_management",
  initialState: initialState,
  reducers: {
    changeTeam: (state, action) => {
      state.selectedTeam = action.payload.selectedTeam;
    },
    updateTeams: (state, action) => {
      state.teams = action.payload.teams;
    },
    updateAgents: (state, action) => {
      state.agents = action.payload.agents;
      state.totalAgent = action.payload.total;
    },
  },
});

export const agentAction = agentSlice.actions;

export default agentSlice.reducer;
