import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notes: [],
};
const callSlice = createSlice({
  name: "call",
  initialState: initialState,
  reducers: {
    addNote: (state, action) => {
      const notes = [...state.notes, action.payload];
      state.notes = notes;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    removeNote: (state, action) => {
      const notes = [...state.notes];
      const idx = notes.map((item) => item._id).indexOf(action.payload);
      if (idx >= 0) {
        notes.splice(idx, 1);
        state.notes = notes;
      }
    },
  },
});

export const callAction = callSlice.actions;

export default callSlice.reducer;
