import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    lng: "en",
    fallbackLng: "en",
    supportedLngs: ["en", "vi", "jp"],
    // backend: {
    //   /* translation file path */
    //   loadPath: "/assets/i18n/{{lng}}.json",
    // },
    // detection: {
    //   order: ["cookie", "htmlTag", "LocalStorage", "path", "subdomain"],
    //   caches: ["LocalStorage"],
    // },

    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    // ns: ["translations"],
    // defaultNS: "translations",
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
