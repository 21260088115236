import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const now = moment().startOf("day");
const initialState = {
  dateValue: 3,
  dateOption: "year",
  selectedTeam: null,
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    changeDate: (state, action) => {
      state.dateValue = action.payload.value;
      state.dateOption = action.payload.option;
    },
    changeSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
  },
});

export const dashboardAction = dashboardSlice.actions;

export default dashboardSlice.reducer;
