import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import styles from "./UtilMenu.module.css";
import { useAuthProvider } from "contexts/authenticationContext";
import { showConfirmPopup } from "components/ui/Notification";

function UtilMenu({ setVisibleChangePassword }) {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(null);
  const { logout } = useAuthProvider();

  useEffect(() => {
    const lan = localStorage.getItem("i18n");
    if (lan) {
      setLanguage(lan);
    }
  });

  const handleChangeLanguage = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18n", lang);
  };

  const handleLogout = () => {
    showConfirmPopup(t("MESSAGE_CONFIRM_LOGOUT"), logout, t("YES"), t("NO"));
  };

  return (
    <div className={styles.container}>
      <div className={styles.language_container}>
        <span className={styles.text}>{t("SELECT_LAN")}</span>
        <Radio.Group value={language} onChange={handleChangeLanguage}>
          <Radio value="vi">
            <div className={styles.language}>
              <ReactCountryFlag countryCode="VN" />
              <span className={styles.text}>{t("VN")}</span>
            </div>
          </Radio>
          <Radio value="en">
            <div className={styles.language}>
              <ReactCountryFlag countryCode="US" />
              <span className={styles.text}>{t("EN")}</span>
            </div>
          </Radio>
          <Radio value="jp">
            <div className={styles.language}>
              <ReactCountryFlag countryCode="JP" />
              <span className={styles.text}>{t("JP")}</span>
            </div>
          </Radio>
        </Radio.Group>
      </div>

      <div className={styles.menu_container} style={{ marginTop: "0.5rem" }}>
        <div
          className={styles.menu_item}
          onClick={() => setVisibleChangePassword(true)}
        >
          <UserOutlined />
          <span className={styles.text}>{t("CHANGE_PASS")}</span>
        </div>
      </div>

      <div className={styles.menu_container}>
        <div className={styles.menu_item} onClick={handleLogout}>
          <LogoutOutlined />
          <span className={styles.text}>{t("LOGOUT")}</span>
        </div>
      </div>
    </div>
  );
}

export default UtilMenu;
