import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "1",
  configList: [],
  totalConfig: 0,
  configFilter: {
    name: null,
    tags: [],
    speaker: null,
    searchType: null,
  },
  loading: false,
};
const tagSlice = createSlice({
  name: "tag",
  initialState: initialState,
  reducers: {
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
    setConfigList: (state, action) => {
      state.configList = action.payload.configs;
      state.totalConfig = action.payload.total;
    },
    setFilterTags: (state, action) => {
      state.configFilter.tags = action.payload;
    },
    setFilterName: (state, action) => {
      state.configFilter.name = action.payload;
    },
    setFilterSpeaker: (state, action) => {
      state.configFilter.speaker = action.payload;
    },
    setFilterSearchType: (state, action) => {
      state.configFilter.searchType = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const tagAction = tagSlice.actions;

export default tagSlice.reducer;
