import { configureStore } from "@reduxjs/toolkit";
import discoveryReducer from "./discoverySlice";
import metricReducer from "./metricsSlice";
import agentReducer from "./agentManagementSlice";
import dashboardSlice from "./dashboardSlice";
import tagSlice from "./tagSlice";
import callSlice from "./callSlice";
import dialogSlice from "./dialogSlice";

const store = configureStore({
  reducer: {
    discovery: discoveryReducer,
    metric: metricReducer,
    agent: agentReducer,
    dashboard: dashboardSlice,
    tag: tagSlice,
    call: callSlice,
    dialog: dialogSlice,
  },
});

export default store;
