import { createContext, useContext, useState, useEffect } from "react";
import { useAuthProvider } from "./authenticationContext";
import authApi from "apis/authentication";

const themeContext = createContext();

export function useTheme() {
  return useContext(themeContext);
}

const theme = {
  default: {
    headerBackground: "#25282f",
    headerTextColor: "#fff",
    avatarColor: "#9babbc",
    textPrimaryColor: "rgba(0, 0, 0, 0.85)",
    textNormalColor: "rgba(0, 0, 0, 0.65)",
    textDescriptionColor: "rgba(0, 0, 0, 0.45)",
    selectColor: "#1b9cfc",
    selectTextColor: "#fff",
    tagChartColor: "#1f77b4",
    scoreChartColor: "#2da02b",
    goodScoreColor: "#52c41a",
    normalScoreColor: "#edc949",
    badScoreColor: "#d62727",
    metricBgColor: "rgb(244, 244, 244)",
    metricPercentColor: "#7f7f7f",
    callChartColor: "#59a14e",
    ahtChartColor: "#e15759",
    numHourChartColor: "#edc949",
    avgScoreChartColor: "#76b8b2",
    primaryUIColor: "#1890ff",
    callLogTagColor: "blue",
    AAvar: "#1a4570",
    BAvar: "rgba(0, 0, 0, 0.65)",
    messageBgHighlight: "linear-gradient(-45deg, #3498db, #27ae60)",
    messageTextHighlight: "#fff",
    AMessageBg: "linear-gradient(80deg, #3498db, #68befc)",
    AMessageText: "#fff",
    BMessageBg: "#f0f0f0",
    BMessageText: "rgba(0, 0, 0, 0.85)",
    metricSearchColor: "#fed130",
    metricSentimentColor: "#4dd9fa",
    metricNameloopColor: "rgba(179, 247, 169, 1)",
    metricStatsColor: "#fca923",
  },
  frt: {
    headerBackground: "#25282f",
    headerTextColor: "#fff",
    avatarColor: "#9babbc",
    textPrimaryColor: "rgba(0, 0, 0, 0.85)",
    textNormalColor: "rgba(0, 0, 0, 0.65)",
    textDescriptionColor: "rgba(0, 0, 0, 0.45)",
    selectColor: "#1b9cfc",
    selectTextColor: "#fff",
    tagChartColor: "#1f77b4",
    scoreChartColor: "#2da02b",
    goodScoreColor: "#52c41a",
    normalScoreColor: "#edc949",
    badScoreColor: "#d62727",
    metricBgColor: "rgb(244, 244, 244)",
    metricPercentColor: "#7f7f7f",
    callChartColor: "#59a14e",
    ahtChartColor: "#e15759",
    numHourChartColor: "#edc949",
    avgScoreChartColor: "#76b8b2",
    primaryUIColor: "#1890ff",
    callLogTagColor: "blue",
    AAvar: "#1a4570",
    BAvar: "rgba(0, 0, 0, 0.65)",
    messageBgHighlight: "linear-gradient(-45deg, #3498db, #27ae60)",
    messageTextHighlight: "#fff",
    AMessageBg: "linear-gradient(80deg, #3498db, #68befc)",
    AMessageText: "#fff",
    BMessageBg: "#f0f0f0",
    BMessageText: "rgba(0, 0, 0, 0.85)",
    metricSearchColor: "#fed130",
    metricSentimentColor: "#4dd9fa",
    metricNameloopColor: "rgba(179, 247, 169, 1)",
    metricStatsColor: "#fca923",
  },
  dico: {
    headerBackground: "#7f48a2",
    headerTextColor: "#fff",
    avatarColor: "#c397aa",
    textPrimaryColor: "rgba(0, 0, 0, 0.85)",
    textNormalColor: "rgba(0, 0, 0, 0.65)",
    textDescriptionColor: "rgba(0, 0, 0, 0.45)",
    selectColor: "#7f48a2",
    selectTextColor: "#fff",
    tagChartColor: "#5f4d9e",
    scoreChartColor: "#e3823c",
    goodScoreColor: "#7030A0",
    normalScoreColor: "#C2C2F3",
    badScoreColor: "#E0CDE4",
    metricBgColor: "#efebee",
    metricPercentColor: "#9192b5",
    callChartColor: "#59a14e",
    ahtChartColor: "#e15759",
    numHourChartColor: "#edc949",
    avgScoreChartColor: "#76b8b2",
    primaryUIColor: "#7f48a2",
    callLogTagColor: "purple",
    AAvar: "#5f4d9e",
    BAvar: "rgba(0, 0, 0, 0.65)",
    messageBgHighlight: "linear-gradient(-45deg, #e3823c, #c397aa)",
    messageTextHighlight: "#fff",
    AMessageBg: "linear-gradient(80deg, #7f48a2, #6f57b7)",
    AMessageText: "#fff",
    BMessageBg: "#f0f0f0",
    BMessageText: "rgba(0, 0, 0, 0.85)",
    metricSearchColor: "#fed130",
    metricSentimentColor: "#4dd9fa",
    metricNameloopColor: "rgba(179, 247, 169, 1)",
    metricStatsColor: "#fca923",
  },
};

export function ThemeProvider({ children }) {
  const { currentUser } = useAuthProvider();
  const [tenantName, setTenantName] = useState(null);

  useEffect(() => {
    if (currentUser) {
      authApi.getTenant().then((resp) => {
        setTenantName(resp.tenant_name);
      });
    }
  }, [currentUser]);
  return (
    <themeContext.Provider
      value={
        tenantName !== null && theme.hasOwnProperty(tenantName)
          ? theme[tenantName]
          : theme["default"]
      }
    >
      {children}
    </themeContext.Provider>
  );
}
