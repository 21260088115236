import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { useAuthProvider } from "contexts/authenticationContext";

// components
import Loading from "components/ui/Loading";
import Layout from "components/layout/main";

const Login = React.lazy(() => import("pages/Login"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const Metric = React.lazy(() => import("pages/Metrics"));
const TermDiscovery = React.lazy(() => import("pages/TermDiscovery"));
const CallDetail = React.lazy(() => import("pages/CallDetail"));
const CallLog = React.lazy(() => import("pages/CallLog"));
const AgentManagement = React.lazy(() => import("pages/AgentManagement"));
const Dictionary = React.lazy(() => import("pages/Dictionary"));
const Tagging = React.lazy(() => import("pages/Tagging"));
const Report = React.lazy(() => import("pages/Report"));
const Task = React.lazy(() => import("pages/Task"));

function Router() {
  const { currentUser } = useAuthProvider();

  return (
    <Switch>
      {currentUser ? (
        <Layout>
          <Route
            path="/dashboard"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Dashboard {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/dictionary"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Dictionary {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/agent-management"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <AgentManagement {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/calllog"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <CallLog {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/metric"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Metric {...props} />
              </React.Suspense>
            )}
            exact
          />
          <Route
            path="/term-discovery"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <TermDiscovery {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/call-detail/:id"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <CallDetail {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/tagging"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Tagging {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/report"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Report {...props} />
              </React.Suspense>
            )}
            exact
          />

          <Route
            path="/task"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Task {...props} />
              </React.Suspense>
            )}
            exact
          />
        </Layout>
      ) : (
        <>
          <Route path="/" exact>
            <Redirect to="/login"></Redirect>
          </Route>
          <Route
            path="/login"
            render={(props) => (
              <React.Suspense fallback={<Loading />}>
                <Login {...props} />
              </React.Suspense>
            )}
            exact
          />
        </>
      )}
    </Switch>
  );
}

export default Router;
