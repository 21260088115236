import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useTheme } from "contexts/themeContext";

import { MdOutlineSpaceDashboard, MdOutlineSupportAgent } from "react-icons/md";
import {
  BiTask,
  BiPhoneCall,
  BiPurchaseTagAlt,
  BiSearch,
  BiLineChart,
} from "react-icons/bi";
import { MdOutlineBugReport } from "react-icons/md";

import { animated, useTransition } from "react-spring";

import styles from "./Sidebar.module.css";

function MenuItem({ icon, path, title, setVisible, more }) {
  const location = useLocation();
  const history = useHistory();
  const isSelected = location.pathname.includes(path);
  const theme = useTheme();

  const textClass = isSelected
    ? `${styles.text} ${styles.text_selected}`
    : styles.text;

  return (
    <div
      className={styles.menu_item_container}
      style={
        isSelected ? { background: theme.selectColor, color: "#fff" } : null
      }
      onClick={() => {
        history.push(path);
        setVisible(false);
      }}
    >
      {icon}
      <div>
        <span className={textClass}>{title}</span>
        {more && <span className={styles.more_text}>{more}</span>}
      </div>
    </div>
  );
}

function Sidebar({ visible, setVisible }) {
  const { t } = useTranslation();
  const transition = useTransition(visible, {
    enter: { left: "0rem" },
    from: { left: "-32rem" },
    leave: { left: "-32rem" },
  });

  const menus = [
    {
      path: "/dashboard",
      name: t("DASHBOARD"),
      icon: <BiLineChart className={styles.icon} />,
      more: null,
    },
    {
      path: "/calllog",
      name: t("CALL_LOG"),
      icon: <BiPhoneCall className={styles.icon} />,
      more: null,
    },
    {
      path: "/metric",
      name: t("METRIC"),
      icon: <MdOutlineSpaceDashboard className={styles.icon} />,
      more: null,
    },
    {
      path: "/tagging",
      name: t("AUTO_TAGGING"),
      icon: <BiPurchaseTagAlt className={styles.icon} />,
      more: null,
    },
    {
      path: "/term-discovery",
      name: t("TERM_DISCOVERY"),
      icon: <BiSearch className={styles.icon} />,
      more: null,
    },
    {
      path: "/agent-management",
      name: t("AGENT_MANAGEMENT"),
      icon: <MdOutlineSupportAgent className={styles.icon} />,
      more: null,
    },
    // {
    //   path: "/dictionary",
    //   name: t("DICTIONARY"),
    //   icon: <FiBook className={styles.icon} />,
    //   more: null,
    // },
    {
      path: "/report",
      name: t("REPORT"),
      icon: <MdOutlineBugReport className={styles.icon} />,
      more: null,
    },
    {
      path: "/task",
      name: "Task",
      icon: <BiTask className={styles.icon} />,
      more: null,
    },
  ];

  return (
    <>
      {transition((style, item) => {
        if (item) {
          return (
            <animated.div className={styles.container} style={style}>
              <div
                className={styles.close_btn}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <CloseOutlined />
              </div>
              {menus.map((item, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    path={item.path}
                    title={item.name}
                    icon={item.icon}
                    setVisible={setVisible}
                    more={item.more}
                  />
                );
              })}
            </animated.div>
          );
        }
        return "";
      })}
    </>
  );
}

export default Sidebar;
