import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metrics: {
    new: [],

    customer_satisfaction: [],
    process_checklist: [],
    customize: [],
  },
  forms: [],
};
const metricSlice = createSlice({
  name: "metrics",
  initialState: initialState,
  reducers: {
    updateAllMetric: (state, action) => {
      state.metrics = action.payload.metrics;
    },
    updatMetric: (state, action) => {
      const tmp = { ...state.metrics };
      const newTag = action.payload.newTag;
      const oldTag = action.payload.oldTag;
      const item = action.payload.item;

      tmp[newTag] = [...tmp[newTag], item];
      tmp[oldTag] = tmp[oldTag].filter((element) => item._id !== element._id);
      state.metrics = tmp;
    },
    addMetric: (state, action) => {
      const tmp = { ...state.metrics };
      const tag = action.payload.tag;
      const item = action.payload.metric;
      tmp[tag] = [...tmp[tag], item];
      state.metrics = tmp;
    },
    addForm: (state, action) => {
      const tmp = [...state.forms];
      tmp.push(action.payload);
      state.forms = tmp;
      // const tmpMetrics = { ...state.metrics };
      // const tag = action.payload.tag;
      // const _id = action.payload._id;
      // const ids = tmpMetrics[tag].map((item) => item._id);
      // const _idx = ids.indexOf(_id);
      // if (_idx >= 0) {
      // }
    },
    removeForm: (state, action) => {
      const tmp = [...state.forms];
      const idx = action.payload.idx;
      if (idx >= 0 && idx < tmp.length) {
        tmp.splice(idx, 1);
        state.forms = tmp;
      }

      // const tmpMetrics = { ...state.metrics };
      // const tag = action.payload.tag;
      // const _id = action.payload._id;
      // const ids = tmpMetrics[tag].map((item) => item._id);
      // const _idx = ids.indexOf(_id);
      // if (_idx >= 0) {
      // }
    },
    updateFormField: (state, action) => {
      const tmp = [...state.forms];
      const { idx, field, value } = action.payload;
      if (idx >= 0 && idx < tmp.length) {
        tmp[idx][field] = value;
        state.forms = tmp;
      }
    },
    updateForms: (state, action) => {
      state.forms = action.payload.forms;
    },
  },
});

export const metricAction = metricSlice.actions;

export default metricSlice.reducer;
