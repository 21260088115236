import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visibleReport: false,
  visibleNote: false,
  visibleAddPhrase: false,
  visibleEditConversation: false,
  selectedSentence: null,
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState: initialState,
  reducers: {
    setVisibleReport: (state, action) => {
      state.visibleReport = action.payload.visible;
      state.selectedSentence = action.payload.data;
    },
    setVisibleNote: (state, action) => {
      state.visibleNote = action.payload.visible;
      state.selectedSentence = action.payload.data;
    },
    setVisibleAddPhrase: (state, action) => {
      state.visibleAddPhrase = action.payload.visible;
      state.selectedSentence = action.payload.data;
    },
    setVisibleEditConversation: (state, action) => {
      state.visibleEditConversation = action.payload.visible;
      state.selectedSentence = action.payload.data;
    },
  },
});

export const dialogAction = dialogSlice.actions;

export default dialogSlice.reducer;
