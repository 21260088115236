import React, { useState } from "react";
import { Input, Modal, Form } from "antd";

import styles from "./ChangePassword.module.css";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";
import authApi from "apis/authentication";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function ChangePassword({ visible, setVisible, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmitForm = (values) => {
    const payload = {
      old_password: values.old_password,
      new_password: values.new_password,
      password_confirm: values.confirm_password,
    };

    setLoading(true);
    authApi
      .chagePassword(payload)
      .then((resp) => {
        setLoading(false);
        showSuccessNotification("Change password has successfully");
        setVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleOk}
      title="Change password"
      width={600}
      okButtonProps={{ loading: loading }}
    >
      <div className={styles.container}>
        <Form form={form} name="change_password" onFinish={handleSubmitForm}>
          <Form.Item
            {...formItemLayout}
            name="old_password"
            label="Old password"
            rules={[
              {
                required: true,
                message: "Please input old password",
              },
            ]}
          >
            <Input.Password placeholder="Old password" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="new_password"
            label="New password"
            rules={[
              {
                required: true,
                message: "Please input new password",
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="confirm_password"
            label="Confirm password"
            rules={[
              {
                required: true,
                message: "Please input confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default ChangePassword;
