import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Popover, Avatar, Radio, Space } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useTheme } from "contexts/themeContext";
import UtilMenu from "./UtilMenu";

import styles from "./Header.module.css";

import { useAuthProvider } from "contexts/authenticationContext";

import Logo from "assets/images/logo.png";
import Logo2 from "assets/images/Logo2.svg";
import ChangePassword from "./ChangePassword";

function Header() {
  const [visibleChangePassword, setVisibleChangePassword] = useState(false);
  const { currentUser } = useAuthProvider();
  const theme = useTheme();

  return (
    <div
      className={styles.container}
      style={{
        background: theme.headerBackground,
      }}
    >
      <Link to="/dashboard">
        <div className={styles.logo_container}>
          <img src={Logo} />
          <span>NamiSense</span>
        </div>
      </Link>

      <div className={styles.avatar}>
        <Popover
          content={
            <UtilMenu setVisibleChangePassword={setVisibleChangePassword} />
          }
          trigger="click"
          placement="bottomRight"
        >
          <Avatar
            size="small"
            style={{
              backgroundColor: theme.avatarColor,
              verticalAlign: "middle",
            }}
            gap={"1rem"}
          >
            {currentUser?.username[0]}
          </Avatar>
        </Popover>

        {visibleChangePassword && (
          <ChangePassword
            visible={visibleChangePassword}
            setVisible={setVisibleChangePassword}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
